import React from "react";
import { Helmet } from "react-helmet";
import EventCard from "../../components/EventCard/EventCard.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar.jsx";
import GeneralConst from "../../consts/GeneralConst.js";
import logo from "../../images/backgrounds/black-runner.jpg";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./EventsPage.scss";

const TITLE = `Events | ${GeneralConst.SITE_NAME}`;

const EventsPage = () => {
  const eventsStrings = GeneralStrings.EVENTS;

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div className="events" id="events">
        <div className="container section-container">
          <div className="row padding-top-50">
            <h2 className="header text-center">{eventsStrings.EVENTS}</h2>
            <p className="description text-center">
              <EventCard
                event={{
                  date: "2024-08-10",
                  imgLoc: logo,
                  link: "https://www.eventbrite.com/e/running-for-real-estate-party-with-a-purpose-rooftop-garden-bash-tickets-932079925147?aff=oddtdtcreator",
                  title:
                    "Running for Real Estate: Party with a Purpose Rooftop Garden Bash",
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventsPage;
